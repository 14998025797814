<template>
    <div>
        <div class='col-12 card'>
            <Toolbar class='mb-3'>
                <template v-slot:start>
                    <div class='flex flex-row align-items-center gap-2' v-if="selectedMode === '图表'">
                        <label for='range'>时间</label>
                        <Dropdown v-model='dataRangeType' :options='dataRangeTypeOptions' optionValue='value'
                                  class='ml-2'
                                  optionLabel='label' @change='onDataRangeTypeChange' placeholder='选择时间' />
                        <div v-if="dataRangeType === 'month'" class='ml-2'>
                            <Calendar id='range' v-model='dateRangeBegin' view='month' dateFormat='yy-mm'
                                      :showIcon='true'
                                      @date-select='onDateChange' />
                        </div>
                        <div v-if="dataRangeType === 'year' || dataRangeType === 'quarter'" class='ml-2'>
                            <Calendar id='range' v-model='dateRangeBegin' view='year' dateFormat='yy' :showIcon='true'
                                      @date-select='onDateChange' />
                        </div>
                        <Dropdown v-if="dataRangeType === 'quarter'" v-model='quarterType' :options='quarterOptions'
                                  @change='onDataRangeTypeChange' placeholder='选择季度' class='ml-2' />
                        <div v-if="dataRangeType === 'day'" class='ml-2'>
                            <Calendar id='range' v-model='dateRangeBegin' dateFormat='yy-mm-dd' :showIcon='true'
                                      @date-select='onDateChange' />
                        </div>
                    </div>
                </template>
                <template v-slot:end>
                    <SelectButton v-model='selectedMode' :options="['表格','图表']" />
                </template>
            </Toolbar>
            <SiteAlarmRecords v-if="selectedMode === '表格'"></SiteAlarmRecords>
            <div v-if="selectedMode === '图表'">
                <div class='flex flex-row justify-content-between'>
                    <div class='col-6 p-2'>
                        <v-chart class='chart' :option='alarmCountBarOp' :theme='chartTheme' :autoresize='true'
                                 style='height: 400px' />
                    </div>
                    <div class='col-6 p-2'>
                        <v-chart class='chart' :option='alarmTypeBarOp' :theme='chartTheme' :autoresize='true'
                                 style='height: 400px' />
                    </div>
                </div>

                <h5>污染因子警报次数统计表</h5>
                <DataTable stripedRows :value='alarmPolList' dataKey='id' selectionMode='single'
                           showGridlines :loading='loading' responsiveLayout='scroll'>
                    <Column field='name' header='污染因子' />
                    <Column field='cb' header='超标警报(次)' :sortable='true' />
                    <Column field='lz' header='零值警报(次)' :sortable='true' />
                    <Column field='hz' header='恒值警报(次)' :sortable='true' />
                    <Column field='dg' header='倒挂警报(次)' :sortable='true' />
                    <Column field='sum' header='累计(次)' :sortable='true' />
                </DataTable>

            </div>
        </div>
    </div>
</template>

<script>
import SiteAlarmRecords from './SiteAlarmsRecords';
import AlarmRecordsService from '../service/AlarmRecordsService';
import VChart from 'vue-echarts';
import { use } from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import { LineChart, PieChart } from 'echarts/charts';
import {
    GridComponent,
    DataZoomInsideComponent,
    LegendComponent,
    MarkLineComponent, MarkPointComponent,
    TitleComponent,
    ToolboxComponent,
} from 'echarts/components';
import { dateFormat, getMonthEndDate, getMonthStartDate, getQuarterEndDate, getQuarterStartDate } from '../utils/date';
import dayjs from 'dayjs';

use([GridComponent, CanvasRenderer, LineChart, PieChart, TitleComponent, LegendComponent, ToolboxComponent, DataZoomInsideComponent, MarkLineComponent, MarkPointComponent]);

export default {
    components: { VChart, SiteAlarmRecords },
    data() {
        return {
            loading: false,
            chartTheme: 'dark',
            selectedMode: '表格',
            dateRangeBegin: null,
            dateRangeEnd: null,
            dataRangeType: 'year',
            alarmPolList: [],
            quarterType: '第一季度',
            dataRangeTypeOptions: [
                { label: '年度统计', value: 'year' },
                { label: '季度统计', value: 'quarter' },
                { label: '月度统计', value: 'month' },
                { label: '天统计', value: 'day' },
            ],
            quarterOptions: ['第一季度', '第二季度', '第三季度', '第四季度'],
            alarmCountBarOp: {
                title: {
                    text: '警报次数趋势图',
                },
                tooltip: {
                    trigger: 'axis',
                },
                backgroundColor: 'transparent',
                xAxis: {
                    type: 'category',
                    data: [],
                },
                yAxis: {
                    type: 'value',
                },
                series: [
                    {
                        data: [],
                        type: 'line',
                    },
                ],
            },
            alarmTypeBarOp: {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow', // 'shadow' as default; can also be 'line' or 'shadow'
                    },
                },
                title: {
                    text: '警报类型次数统计',
                },
                backgroundColor: 'transparent',
                legend: {},
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true,
                },
                xAxis: {
                    type: 'value',
                },
                yAxis: {
                    type: 'category',
                    data: [],
                },
                series: [
                    {
                        type: 'bar',
                        stack: 'total',
                        label: {
                            show: true,
                        },
                        emphasis: {
                            focus: 'series',
                        },
                        data: [],
                    },
                ],
            },
        };
    },
    alarmRecordService: null,
    watch: {
        '$appState.colorScheme'() {
            this.chartTheme = this.$appState.colorScheme;
        },
    },
    created() {
        this.alarmRecordService = new AlarmRecordsService();
        this.dataRangeType = 'month';
        this.dateRangeBegin = dayjs().startOf('month').toDate();
        this.dateRangeEnd = dayjs().endOf('month').toDate();
        this.chartTheme = this.$appState.colorScheme;
    },
    mounted() {
        this.loadAlarmCountChart();
        this.loadAlarmTypeChart();
    },
    methods: {
        loadAlarmCountChart() {
            const siteId = this.$store.getters.currentSelectedSite.id;
            const start = dayjs(this.dateRangeBegin);
            const number = dayjs(this.dateRangeEnd).diff(start, 'day');
            const x = [];
            for (let i = 0; i < number; i++) {
                x.push(start.add(i, 'day').format('MM/DD'));
            }
            this.alarmCountBarOp.xAxis.data = x;

            this.alarmRecordService.siteAlarmAnalyzeByRange(siteId, null, this.dateRangeBegin.getTime(), this.dateRangeEnd.getTime()).then((res) => {
                this.alarmCountBarOp.series[0].data = [];
                if (res.data.success && res.data.data.length > 0) {
                    // console.log(res.data);
                    const tempMap = {};
                    const polMap = {};
                    res.data.data.forEach((e) => {
                        const date = dayjs(e.alarmCreateTs).format('MM/DD');
                        if (tempMap[date]) {
                            tempMap[date] += 1;
                        } else {
                            tempMap[date] = 1;
                        }

                        const alarmType = e.alarmType + '';
                        const alarmPol = e.alarmPol;

                        if (alarmPol !== undefined && alarmPol !== null) {
                            let item = polMap[alarmPol];
                            if (item == null) {
                                item = { 'cb': 0, 'hz': 0, 'lz': 0, 'dg': 0, 'sum': 0, 'name': alarmPol };
                            }
                            if (alarmType === 'POLLUTION_EXCESSIVE')
                                item.cb += 1;
                            else if (alarmType === 'POLLUTION_LONG_CONSTANT')
                                item.hz += 1;
                            else if (alarmType === 'ZERO')
                                item.lz += 1;
                            else if (alarmType.indexOf('DATA_ABNORMAL') > -1)
                                item.dg += 1;

                            item.sum = item.cb + item.hz + item.lz + item.dg;

                            polMap[alarmPol] = item;
                        }
                    });
                    x.forEach((d) => {
                        this.alarmCountBarOp.series[0].data.push(tempMap[d] ?? 0);
                    });

                    // Table
                    this.alarmPolList = [];
                    Object.keys(polMap).forEach((k) => {
                        this.alarmPolList.push(polMap[k]);
                    });
                    // console.log(this.alarmPolList);

                }
            });
        },
        loadAlarmTypeChart() {
            const siteId = this.$store.getters.currentSelectedSite.id;
            this.alarmRecordService.alarmAnalyzeBySite(null, null, siteId, this.dateRangeBegin.getTime(), this.dateRangeEnd.getTime()).then((res) => {
                this.alarmTypeBarOp.series[0].data = [];
                if (res.data.success && res.data.data.length > 0) {
                    const tempList = [
                        { 'key': '超标', 'value': res.data.data[0].pollutionExcessive },
                        { 'key': '离线', 'value': res.data.data[0].offline },
                        { 'key': '恒值', 'value': res.data.data[0].pollutionLongConstant },
                        { 'key': '零值', 'value': res.data.data[0].zero },
                        { 'key': '倒挂', 'value': res.data.data[0].dataAbnormal },
                    ];
                    tempList.sort((a, b) => a.value - b.value);
                    this.alarmTypeBarOp.yAxis.data = tempList.map((e) => e.key);
                    this.alarmTypeBarOp.series[0].data = tempList.map((e) => e.value);
                }

            });
        },
        loadAlarmPolTable() {

        },
        stateClass(state) {
            return [
                {
                    'normalState': state === '正常',
                    'failState': state !== '正常',
                },
            ];
        },
        onDataRangeTypeChange() {
            this.dateRangeBegin = new Date();
            this.getBeginTimeAndEndTime();
            console.log('begin:', dateFormat(this.dateRangeBegin), 'end:', dateFormat(this.dateRangeEnd));

            this.loadAlarmCountChart();
            this.loadAlarmTypeChart();
        },
        onDateChange() {
            this.getBeginTimeAndEndTime();
            console.log('begin:', dateFormat(this.dateRangeBegin), 'end:', dateFormat(this.dateRangeEnd));

            this.loadAlarmCountChart();
            this.loadAlarmTypeChart();
        },
        getBeginTimeAndEndTime() {
            const dates = this.dateRangeBegin;
            if (this.dataRangeType === 'year') {
                const year = dates.getFullYear();
                const begin = new Date(year + '-01-01 00:00:00');
                const end = new Date(year + '-12-30 23:59:59');
                this.dateRangeBegin = begin;
                this.dateRangeEnd = end;
            } else if (this.dataRangeType === 'quarter') {
                let quarter = '';
                // 第一季度
                if (this.quarterType === '第一季度') {
                    quarter = new Date(dates.getFullYear() + '-01-01 00:00:00');
                }
                // 第二季度
                if (this.quarterType === '第二季度') {
                    quarter = new Date(dates.getFullYear() + '-04-01 00:00:00');
                }
                // 第三季度
                if (this.quarterType === '第三季度') {
                    quarter = new Date(dates.getFullYear() + '-07-01 00:00:00');
                }
                // 第四季度
                if (this.quarterType === '第四季度') {
                    quarter = new Date(dates.getFullYear() + '-10-01 00:00:00');
                }
                this.dateRangeBegin = getQuarterStartDate(quarter);
                this.dateRangeEnd = getQuarterEndDate(quarter);
            } else if (this.dataRangeType === 'month') {
                // 月初
                this.dateRangeBegin = getMonthStartDate(dates);
                // 月末
                this.dateRangeEnd = getMonthEndDate(dates);
            } else if (this.dataRangeType === 'day') {
                const date = new Date(dates.getTime());
                date.setHours(0);
                date.setMinutes(0);
                date.setSeconds(0);
                date.setMilliseconds(0);
                this.dateRangeBegin = new Date(date.getTime());
                date.setDate(date.getDate() + 1);
                this.dateRangeEnd = date;
            }
        },
    },
};
</script>

<style lang='scss' scoped>
.normalState {
    font-weight: 700;
    color: #0BD18A;
}

.failState {
    font-weight: 700;
    color: #FC6161;
}

@media screen and (max-width: 960px) {
    .panel-demo {
        .toolbar-demo.card {
            overflow: auto;
        }
    }
}
</style>
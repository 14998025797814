import request from '../config/axios';

export default class AlarmRecordsService {
    alarmPageList = (params) => request({
        url: '/alarm/page', method: 'get', params: {
            ...params,
        },
    });

    alarmTypeAnalyzeOfMonth = (params) => request({
        url: '/alarm/alarmSiteStatis', method: 'get', params: {
            ...params,
        },
    });

    loadAlarmCountTrendOfMonthData = (params) => request({
        url: '/alarm/alarmCountOfEachDayInMonth', method: 'get', params: {
            ...params,
        },
    });

    alarmAnalyzeBySite = (regionId, enterpriseId, siteId, startTime, endTime) => {
        return request({
            url: '/workReport/alarmAnalyzeBySite',
            method: 'get',
            params: {
                'regionId': regionId,
                'enterpriseId': enterpriseId,
                'siteId': siteId,
                'startTime': startTime,
                'endTime': endTime,
            },
        });
    };
    siteAlarmAnalyzeByRange = (siteId, alarmType, startTime, endTime) => {
        return request({
            url: '/workReport/historyAlarmByDateRange',
            method: 'get',
            params: { 'siteId': siteId, 'alarmType': alarmType, 'startTime': startTime, 'endTime': endTime },
        });
    };

}

<template>
    <div>
        <DataTable stripedRows :value='alarmList' :lazy='true' :paginator='true' :rows='15'
                   ref='dt' dataKey='id' selectionMode='single' showGridlines
                   :totalRecords='totalRecords' :loading='loading' @page='onPage($event)'
                   paginatorTemplate='CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown'
                   :rowsPerPageOptions='[15,20]' responsiveLayout='scroll'
                   currentPageReportTemplate='当前页显示第 {first} 到 第 {last} 条记录, 总共 {totalRecords} 条记录'>
            <template #header>
                <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
                    <h5 class="m-0">历史警报记录</h5>
                    <span class="block mt-2 md:mt-0 p-input-icon-left"></span>
                </div>
            </template>

            <Column header='警报时间' class='col-2'>
                <template #body='slotProps'>
                    {{ formatCreateTs(slotProps.data.createTs) }}
                </template>
            </Column>
            <Column field='title' header='警报内容' />
            <Column field='duration' header='持续时长' class='col-1' />
            <Column field='stateName' header='当前状态' class='col-1'>
                <template #body='slotProps'>
                    <Tag :value='slotProps.data.stateName'
                         :severity="slotProps.data.stateName === '已解除'?'success':'danger'"></Tag>
                </template>
            </Column>
            <Column field='taskRemark' header='故障原因' class='col-2' />
            <Column field='gzbg' header='是否出具报告' class='col-1'>
                <template #body='slotProps'>
                    <span v-if='slotProps.data.gzbg === null'>否</span>
                    <span v-else class='underline text-blue-500' @click='showGzbgDetails(slotProps.data)'>点击查看</span>
                </template>
            </Column>
        </DataTable>

        <Sidebar v-model:visible='visibleLeft' class='p-sidebar-lg'>
            <GZBG :obj='JSON.parse(currentRow.gzbg)'></GZBG>
        </Sidebar>
    </div>
</template>
<script>
import AlarmRecordsService from '../service/AlarmRecordsService';
import dayjs from 'dayjs';
import GZBG from './GZBG';

export default {
    components: { GZBG },
    data() {
        return {
            alarmList: null,
            visibleLeft: false,
            currentRow: {},
            totalRecords: 0,
            loading: true,
            lazyParams: {},
            total: 0,
            current: 1,
            size: 15,
        };
    },
    alarmRecordsService: null,
    created() {
        this.alarmRecordsService = new AlarmRecordsService();
    },
    mounted() {
        this.lazyParams = {
            'entityId': this.$store.getters.currentSelectedSite.id,
            'sort': 1,
            'current': this.current,
            'size': this.size,
        };

        this.loadLazyData();
    },
    methods: {
        loadLazyData() {
            this.loading = true;

            this.alarmRecordsService.alarmPageList(this.lazyParams).then(res => {
                this.alarmList = [];
                if (res.data.success) {
                    this.alarmList = res.data.data.records;
                    this.totalRecords = res.data.data.total;
                }
                console.log(this.alarmList);
                this.loading = false;
            });
        },
        onPage(event) {
            this.lazyParams.current = event.page + 1;
            this.lazyParams.size = event.rows;
            this.loadLazyData();
        },
        formatCreateTs(value) {
            return dayjs(value).format('YYYY年MM月DD日 HH:mm:ss');
        },
        showGzbgDetails(row) {
            this.currentRow = row;
            this.visibleLeft = true;
        },
    },
};


</script>
<style lang='scss' scoped>
</style>